//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Instagram from '~/assets/img/socials/instagram.svg?inline';
import Facebook from '~/assets/img/socials/facebook.svg?inline';
import LinkedIn from '~/assets/img/socials/linkedin.svg?inline';

export default {
  name: 'UiLinkSocial',
  components: {
    Instagram,
    Facebook,
    LinkedIn,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
}
