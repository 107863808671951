//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isRichtextFilled } from '~/utils/helpers';

export default {
  name: 'BlokHomeStories',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isVisibleAos: false,
      isVisibleSwiper: false,
      optionsBody: {
        slidesPerView: 1,
        watchOverflow: true,
        autoHeight: true,
        spaceBetween: 40,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      optionsFooter: {
        slidesPerView: 1,
        watchOverflow: true,
        spaceBetween: 40,
        allowTouchMove: false,
      },
    }
  },
  computed: {
    aosAnimate() {
      return this.isVisibleAos ? 'aos-animate' : null;
    },
    heading() {
      switch(this.index) {
        case 0:
          return 'h1';
        default:
          return 'h2';
      }
    },
    swiperBody() {
      return this.$refs.refSwiperBody.$swiper;
    },
    swiperFooter() {
      return this.$refs.refSwiperFooter.$swiper;
    },
    chatEasyAvatar() {
      return this.blok.chat_easy_avatar?.filename;
    },
    isChatEasyMessage() {
      return isRichtextFilled(this.blok.chat_easy_message);
    },
  },
  methods: {
    aosVisibilityChanged(isVisible) {
      this.isVisibleAos = isVisible;
    },
    swiperVisibilityChanged(isVisible) {
      this.isVisibleSwiper = isVisible;

      if (isVisible) {
        this.swiperBody.autoplay.start();
      } else {
        this.swiperBody.autoplay.stop();
      }
    },
    autoplayStart() {
      this.$el.classList.remove('swiper-pagination-pause');
    },
    autoplayStop() {
      this.$el.classList.add('swiper-pagination-pause');
    },
    handleSwiperStop() {
      this.swiperBody.autoplay.stop();
    },
    handleSwiperStart() {
      if (this.isVisibleSwiper) {
        this.swiperBody.autoplay.start();
      }
    },
  },
  mounted() {
    this.swiperBody.controller.control = this.swiperFooter;
    this.swiperFooter.controller.control = this.swiperBody;

    this.swiperBody
      .on('autoplayStart', () => {
        this.autoplayStart();
      })
      .on('autoplayStop', () => {
        this.autoplayStop();
      });
  },
}
